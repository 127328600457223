<template>
    <div v-if="isConfiguratorReady && !isLoading" class="col-span-2">
        <h3 class="mb">
            {{ currentArticle.designation }}
        </h3>
        <span class="text-gray-500">à partir de {{ formatPrice(productConfiguration.minPrice) }} net ht</span>
    </div>
</template>

<script>
import { formatPrice } from "../../helpers/priceHelper";

export default {
    name: "article-info",
    data() {
        return {
            formatPrice,
        };
    },
    computed: {
        isLoading() {
            return this.$store.getters["project/isLoading"];
        },
        currentArticle() {
            return this.$store.getters["project/currentArticle"];
        },
        productConfiguration() {
            return this.$store.getters["project/productConfiguration"];
        },
        isConfiguratorReady() {
            return this.$store.getters["project/isConfiguratorReady"];
        },
    },
};
</script>
