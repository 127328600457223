<template>
    <div class="grid w-44 grid-cols-4 rounded-sm border-2 border-gray-200">
        <button
            :class="[
                `${amountForm === 0 ? 'text-gray-300' : 'text-gray-500'}`,
                'col-span-1 border-r-2 border-gray-200 text-3xl focus:outline-none focus-visible:outline-none',
            ]"
            @click="decrement"
        >
            -
        </button>
        <input
            type="number"
            :value="amountForm"
            class="col-span-2 appearance-none text-center"
            @change="setAmount($event)"
        />
        <button
            class="col-span-1 border-l-2 border-gray-200 text-3xl text-gray-500 focus:outline-none focus-visible:outline-none"
            @click="increment"
        >
            +
        </button>
    </div>
</template>

<script>
import self from "../../../index";

const { modules } = self.app;

export default {
    name: "counter",
    props: {
        amount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            amountForm: 0,
        };
    },
    watch: {
        amount: function (amount) {
            this.amountForm = amount;
        },
    },
    created() {
        this.amountForm = this.amount;
    },
    methods: {
        increment() {
            this.amountForm++;
            this.$emit("update-amount", this.amountForm);
        },
        decrement() {
            if (this.amountForm > 0) {
                this.amountForm--;
            }

            this.$emit("update-amount", this.amountForm);
        },
        setAmount(event) {
            const { value } = event.target;
            this.amountForm = parseInt(value, 10);
            this.$emit("update-amount", this.amountForm);
        },
    },
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
