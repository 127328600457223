<template>
    <div class="relative">
        <div class="relative w-full overflow-hidden">
            <div
                v-for="(img, i) in images"
                :id="`slide-${i}`"
                :key="i"
                :class="[
                    'relative inset-0 float-left mr-[-100%] block w-full transform transition-all duration-500 ease-in-out backface-hidden',
                    isActive(i) && 'active',
                    !isActive(i) && '-left-full',
                ]"
            >
                <img class="mx-auto max-h-96 w-auto" :src="img" :alt="`Image ${i}`" />
            </div>
            <div class="absolute bottom-0 flex h-full w-full items-center justify-between">
                <button type="button" class="h-12 w-12 rounded-full bg-white/50 text-black" @click="prev">
                    <span class="icon-arrow-left5 flex items-center justify-center text-2xl"></span>
                </button>
                <button type="button" class="h-12 w-12 rounded-full bg-white/50 text-black" @click="next">
                    <span class="icon-arrow-right5 flex items-center justify-center text-2xl"></span>
                </button>
            </div>
        </div>
        <div class="relative mt-4 grid w-full grid-cols-6 overflow-hidden">
            <button
                type="button"
                @click="setActive(i)"
                v-for="(img, i) in images"
                :id="`slide-${i}`"
                :key="i"
                :class="['border-2', isActive(i) && 'border-gray-800', !isActive(i) && '-left-full border-transparent']"
            >
                <img
                    :class="['h-auto w-full border-2 border-transparent p-1', isActive(i) && ' border-gray-600']"
                    :src="img"
                    :alt="`Image ${i} miniature`"
                />
            </button>
        </div>
    </div>
</template>

<script>
import eventBus from "../services/eventBus";

export default {
    name: "carousel",
    data() {
        return {
            active: 0,
            currentColor: null,
        };
    },
    methods: {
        next() {
            if (this.active < this.images.length - 1) {
                this.active++;
            } else {
                this.active = 0;
            }
        },
        prev() {
            if (this.active > 0) {
                this.active--;
            } else {
                this.active = this.images.length - 1;
            }
        },
        setActive(index) {
            this.active = index;
        },
        isActive(index) {
            return this.active === index;
        },
        setCurrentColor(color) {
            this.currentColor = color;
        },
    },
    mounted() {
        eventBus.on("updateCarouselImage", (color) => {
            this.setCurrentColor(color);
            this.setActive(0);
        });
    },
    computed: {
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
        images() {
            return this.product.images
                .filter((img) => {
                    if (this.currentColor === null) {
                        return true;
                    }
                    return img.carouselColor === this.currentColor;
                })
                .map((img) => img.url);
        },
    },
};
</script>
