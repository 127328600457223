<template>
    <div>
        <div class="mb-2 text-xl font-bold">Choisissez votre emplacement et design</div>
        <div class="mb-2 font-bold">Emplacements et design</div>
        <div>
            <div
                v-for="(location, locationId) in product.locations"
                :key="locationId"
                :class="[
                    locationToggled === locationId && 'toggle',
                    selectedLocations.includes(locationId) && 'border-theme-blue bg-theme-blue-100',
                    'location py-4',
                ]"
            >
                <div
                    v-if="isDisabledLocation(locationId)"
                    class="flex cursor-not-allowed flex-row flex-wrap items-center justify-between px-6 font-bold"
                >
                    <span class="flex items-center opacity-60">
                        <input
                            type="checkbox"
                            :class="[
                                'form-tick mr-4 h-5 w-5 cursor-not-allowed appearance-none border-2',
                                'cursor-pointer rounded border-gray-400 bg-white checked:border-theme-blue checked:bg-theme-blue',
                            ]"
                            disabled
                        />
                        {{ getLocationDetails(locationId).name }}
                    </span>
                    <span class="opacity-60">
                        <i class="icon-arrow-up4" />
                    </span>
                    <span v-if="isDisabledLocationByFinishing(locationId)" class="w-full text-red-600">
                        Cet emplacement n'est pas disponible avec cette finition ({{
                            getFinishingLabel(article.finishing)
                        }})
                    </span>
                </div>
                <div v-else class="flex flex-row items-center justify-between px-6 font-bold">
                    <span class="flex items-center">
                        <input
                            :id="`location-${locationId}`"
                            type="checkbox"
                            :class="[
                                'form-tick mr-4 h-5 w-5 appearance-none border-2',
                                'cursor-pointer rounded border-gray-400 bg-white checked:border-theme-blue checked:bg-theme-blue',
                            ]"
                            :checked="selectedLocations.includes(locationId)"
                            @click="selectLocation(locationId)"
                        />
                        {{ getLocationDetails(locationId).name }}
                    </span>
                    <span class="cursor-pointer" @click="toggleLocation(locationId)">
                        <i :class="[{ hidden: locationToggled === locationId }, 'icon-arrow-up4']" />
                        <i :class="[{ hidden: locationToggled !== locationId }, 'icon-arrow-down5']" />
                    </span>
                </div>
                <div class="content ml-10">
                    <span>
                        Zone {{ locationId }} - Dimensions : {{ getLocationDetails(locationId).dimension.width }}cm x
                        {{ getLocationDetails(locationId).dimension.height }}cm
                    </span>
                    <CustomizationLocation
                        :location-id="locationId"
                        @customization-update="updateCustomization($event)"
                    />
                </div>
            </div>
        </div>
        <div class="mt-4 flex justify-between">
            <button
                type="button"
                class="flex border border-theme-blue bg-white px-4 py-2 uppercase text-theme-blue"
                @click="previousStep"
            >
                précédent
            </button>
            <button type="button" class="flex bg-theme-blue px-4 py-2 uppercase text-white" @click="nextStep">
                suivant
            </button>
        </div>
    </div>
</template>

<script>
import eventBus from "../../services/eventBus";
import { getLocationDetails } from "../../helpers/locationHelper";
import CustomizationLocation from "./personalization/customizationLocation.vue";
import { isLocationProductAvailableForFinishing } from "../../helpers/projectHelper";
import { PRODUCT_FINISHING_PRINTING } from "../../services/constants";

export default {
    name: "personalization",
    components: {
        CustomizationLocation,
    },
    data() {
        return {
            locationToggled: "",
            selectedLocations: [],
            getLocationDetails,
        };
    },
    computed: {
        article() {
            return this.$store.getters["project/currentArticle"];
        },
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
    },
    watch: {
        article: function (article) {
            this.selectedLocations = article.customization.map((custom) => custom.location);
        },
    },
    mounted() {
        this.selectColor();
    },
    created() {
        this.selectedLocations = this.article.customization.map((custom) => custom.location);
    },
    methods: {
        toggleLocation(index) {
            this.locationToggled = this.locationToggled === index ? null : index;
        },
        closeLocation() {
            this.locationToggled = null;
        },
        openLocation(index) {
            this.locationToggled = index;
        },
        selectLocation(index) {
            if (this.selectedLocations.includes(index)) {
                this.selectedLocations = this.selectedLocations.filter((value) => value !== index);
                this.closeLocation();
                this.deleteCustomization(index);
            } else {
                this.selectedLocations = [...this.selectedLocations, index];
                this.openLocation(index);
            }
        },
        isDisabledLocation(location) {
            if (!this.article.finishing) {
                return false;
            }

            const isLocationDisabledFromFinishing = this.isDisabledLocationByFinishing(location);

            const isLocationDisabledByAnOther = this.selectedLocations.some((loc) =>
                getLocationDetails(loc).locationsToDisable[this.article.finishing.toLowerCase()].includes(location),
            );

            return isLocationDisabledFromFinishing || isLocationDisabledByAnOther;
        },
        isDisabledLocationByFinishing(location) {
            return !isLocationProductAvailableForFinishing(this.product.reference, location, this.article.finishing);
        },
        deleteCustomization(location) {
            let customizationList = [...this.article.customization];

            customizationList = customizationList.filter((cstm) => cstm.location !== location);

            this.$store.dispatch("project/updateCurrentArticle", { prop: "customization", value: customizationList });
            this.$store.dispatch("project/removeAsset", location);
        },
        updateCustomization(customization) {
            const customizationList = [...this.article.customization];
            const customizationIdx = customizationList.findIndex((cstm) => cstm.location === customization.location);

            if (customizationIdx < 0) {
                customizationList.push(customization);
            } else {
                customizationList[customizationIdx] = customization;
            }

            if (!this.selectedLocations.includes(customization.location)) {
                this.selectedLocations = [...this.selectedLocations, customization.location];
            }

            this.$store.dispatch("project/updateCurrentArticle", { prop: "customization", value: customizationList });
        },
        nextStep() {
            eventBus.emit("setTab", 3);
        },
        previousStep() {
            eventBus.emit("setTab", 1);
        },
        selectColor() {
            eventBus.emit("updateCarouselImage", "LOCATIONS");
        },
        getFinishingLabel(finishing) {
            return finishing === PRODUCT_FINISHING_PRINTING ? "transfert numérique" : "broderie";
        },
    },
};
</script>

<style lang="scss">
.location {
    @apply mb-4 rounded border-2 border-transparent bg-gray-100;
    .content {
        @apply hidden;
    }
    &.toggle {
        .content {
            @apply block;
        }
    }
}
</style>
