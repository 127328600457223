<template>
    <div :class="['mb-16 grid grid-cols-4 grid-rows-project gap-y-4', { hidden: isLoading }]">
        <div class="col-span-3 row-span-1">
            <h2 class="mb-1">Personnalisation de vêtement</h2>
            <h3>
                <input
                    v-show="isProjectNameEditing"
                    ref="projectName"
                    type="text"
                    class="w-full text-xl font-extrabold text-black underline focus:outline-none"
                    style="text-underline-position: under"
                    :value="project.name"
                    @blur="projectNameEdited($event)"
                />
                <span v-show="!isProjectNameEditing">
                    {{ project.name }}
                </span>
                <span
                    v-show="!isProjectNameEditing"
                    class="ml-2 cursor-pointer text-xs font-normal text-gray-800"
                    @click="editProjectName"
                >
                    <i class="icon-edit" />
                    <span class="underline">Renommer</span>
                </span>
            </h3>
            <span class="mb-4 text-xs text-red-600" v-show="isProjectNameTooLong"
                >Le nom du projet est limité à 30 caractères</span
            >
        </div>
        <div class="col-span-1 row-span-1 flex flex-col items-center justify-center xl:row-span-2">
            <button
                type="button"
                :class="['btn-action btn-blue mb-4 h-11 w-52 max-w-full', { 'disable': !isProjectValid(project) }]"
                :disabled="!isProjectValid(project)"
                @click.prevent="createQuote()"
            >
                Créer un devis
            </button>
            <button type="button" class="btn-action btn-white h-11 w-52 max-w-full" @click="addArticle()">
                Ajouter un article
            </button>
        </div>
        <div class="col-span-4 row-span-1 grid grid-cols-1 xl:col-span-3">
            <div
                v-for="article in articles"
                :key="article.id"
                :class="[
                    `${isCurrentArticle(article.id) ? 'border-theme-blue bg-theme-blue-100' : 'border-gray-100 bg-gray-100'}`,
                    'mb-2 grid grid-cols-12 border p-1',
                ]"
            >
                <div class="col-span-1 flex items-center justify-center">
                    <img class="h-20" :src="article.image" :alt="article.imageAlt" />
                </div>
                <div class="col-span-7 pl-2">
                    <div class="cursor-pointer font-bold" @click="selectArticle(article.id)">
                        {{ article.designation }}
                    </div>
                    <div class="mb-1 text-xs text-gray-500">
                        <span>{{ getColorLabel(article.color) }}</span>
                        <span v-if="article.grammage !== undefined">
                            <span v-if="article.grammage !== 0">, {{ article.grammage }}g/m²</span>
                            <span v-else>, <span class="text-red-500">Non défini</span></span>
                        </span>
                        <span> | </span>
                        <span
                            v-if="article.customization.length < 1"
                            :class="{ 'text-red-500': !isArticleCustomizationValid(article) }"
                        >
                            Non défini</span
                        >
                        <span
                            v-for="(custom, index) in article.customization"
                            v-else
                            :key="custom.location"
                            :class="{ 'text-red-500': !isArticleCustomizationValid(article) }"
                        >
                            {{
                                `${getLocationName(custom.location)}${article.customization.length !== index + 1 ? "," : ""}`
                            }}
                        </span>
                        <span> | </span>
                        <span :class="{ 'text-red-500': !isArticleFinishingValid(article) }">
                            {{ getFinishingLabel(article.finishing) }}
                        </span>
                        <span> | </span>
                        <span
                            v-for="(size, name, index) in article.quantity"
                            :key="index"
                            :class="{
                                'text-red-500':
                                    !isArticleQuantityValid(article) || !isArticleProductQuantityValid(article),
                            }"
                        >
                            {{
                                `${name.toUpperCase()} x ${size}${Object.keys(article.quantity).length !== index + 1 ? "," : ""}`
                            }}
                        </span>
                    </div>
                    <div
                        :class="[
                            {
                                'text-theme-green': getArticleStatus(article) === ARTICLE_STATUS_FINISH,
                                'text-red-500': getArticleStatus(article) === ARTICLE_STATUS_NOT_FINISH,
                                'italic text-gray-700': getArticleStatus(article) === ARTICLE_STATUS_EDITING,
                            },
                            'text-sm font-bold',
                        ]"
                    >
                        {{ getArticleStatusLabel(article) }}
                    </div>
                </div>
                <div class="col-span-4 flex items-center justify-center">
                    <div class="mx-2 cursor-pointer text-sm">
                        <i class="icon-duplicate" />
                        <span class="underline" @click="duplicateArticle(article.id)">Dupliquer</span>
                    </div>
                    <div class="mx-2 cursor-pointer text-sm" @click="deleteArticle(article.id)">
                        <i class="icon-trash-border" />
                        <span class="underline">Supprimer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";

import { getColorLabel } from "../helpers/colorHelper";
import { getLocationName } from "../helpers/locationHelper";
import {
    getArticleStatusNoEdit,
    getFinishingLabel,
    isArticleCustomizationValid,
    isArticleFinishingValid,
    isArticleProductQuantityValid,
    isArticleQuantityValid,
    isProjectValid,
} from "../helpers/projectHelper";
import {
    ARTICLE_STATUS,
    ARTICLE_STATUS_EDITING,
    ARTICLE_STATUS_FINISH,
    ARTICLE_STATUS_NOT_FINISH,
    PRODUCT_FINISHING,
} from "../services/constants";
import generateQuote from "../services/quoteGenerator";

export default {
    name: "project",
    data() {
        return {
            PRODUCT_FINISHING,
            ARTICLE_STATUS,
            ARTICLE_STATUS_FINISH,
            ARTICLE_STATUS_NOT_FINISH,
            ARTICLE_STATUS_EDITING,
            isProjectNameEditing: false,
            isProjectNameTooLong: false,
        };
    },
    computed: {
        isLoading() {
            return this.$store.getters["project/isLoading"];
        },
        project() {
            return this.$store.getters["project/project"];
        },
        articles() {
            return this.$store.getters["project/articles"];
        },
        currentArticle() {
            return this.$store.getters["project/currentArticle"];
        },
    },
    methods: {
        ...mapMutations("project", ["CHANGE_CURRENT_ARTICLE", "UPDATE_LOADER"]),
        getLocationName,
        isArticleCustomizationValid,
        isArticleFinishingValid,
        isArticleQuantityValid,
        isArticleProductQuantityValid,
        getArticleStatusNoEdit,
        getColorLabel,
        getFinishingLabel,
        isProjectValid,
        getArticleStatus(article) {
            return article.id === this.currentArticle?.id ? ARTICLE_STATUS_EDITING : getArticleStatusNoEdit(article);
        },
        getArticleStatusLabel(article) {
            const articleStatus = this.getArticleStatus(article);
            let label = ARTICLE_STATUS[ARTICLE_STATUS_NOT_FINISH];

            if (ARTICLE_STATUS[articleStatus] !== undefined) {
                label = ARTICLE_STATUS[articleStatus];
            }

            return label;
        },
        isCurrentArticle(articleId) {
            return this.currentArticle && articleId === this.currentArticle.id;
        },
        async duplicateArticle(articleId) {
            await this.$store.dispatch("project/duplicateArticle", articleId);
        },
        addArticle() {
            this.CHANGE_CURRENT_ARTICLE(0);
        },
        selectArticle(articleId) {
            this.CHANGE_CURRENT_ARTICLE(articleId);
        },
        deleteArticle(articleId) {
            this.$store.dispatch("project/deleteArticle", articleId);
            this.$store.dispatch("project/removeAssets", articleId);
        },
        createQuote() {
            if (isProjectValid(this.project)) {
                this.UPDATE_LOADER({ status: true, message: "Enregistrement du devis en cours ..." });
                generateQuote(this.project);
            }
        },
        editProjectName() {
            this.isProjectNameEditing = true;
            this.$nextTick(() => {
                this.$refs.projectName.focus();
                this.$refs.projectName.select();
            });
        },
        projectNameEdited(event) {
            this.isProjectNameEditing = false;

            if (event.target.value.length > 30) {
                this.isProjectNameTooLong = true;
                event.target.value = event.target.value.substring(0, 30);
            } else {
                this.isProjectNameTooLong = false;
            }

            this.$store.dispatch("project/updateProjectName", event.target.value);
        },
    },
};
</script>
