export default {
    getOne(token, projectId) {
        return fetch(`/projects/${projectId}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-KEY": token,
            },
        });
    },
    post(token, payload) {
        return fetch("/projects", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-KEY": token,
            },
            body: JSON.stringify(payload),
        });
    },
    put(token, projectId, payload) {
        return fetch(`/projects/${projectId}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-KEY": token,
            },
            body: JSON.stringify(payload),
        });
    },
    delete(projectId) {
        return fetch(`/projects/${projectId}`, {
            method: "DELETE",
        });
    },
    uploadFile(token, projectId, articleId, payload) {
        const formData = new FormData();
        formData.append("file", payload.file, payload.fileName);
        formData.append("type", payload.type);

        return fetch(`/projects/${projectId}/${articleId}/upload`, {
            method: "POST",
            headers: {
                "X-API-KEY": token,
            },
            body: formData,
        });
    },
    removeAsset(token, projectId, articleId, location = null) {
        let url = `/projects/${projectId}/${articleId}`;
        if (location) {
            url = `${url}/${location}`;
        }
        return fetch(url, {
            method: "DELETE",
            headers: {
                "X-API-KEY": token,
            },
        });
    },
    generateArticleBat(token, projectId, payload) {
        return fetch(`/projects/${projectId}/generatebat`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-KEY": token,
            },
            body: JSON.stringify(payload),
        });
    },
};
