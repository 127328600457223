import { createStore } from "vuex";

import AppModule from "./app";
import ProjectModule from "./project";

export default createStore({
    modules: {
        project: ProjectModule,
        app: AppModule,
    },
});

// export default new Vuex.Store({
//     modules: {
//         project: ProjectModule,
//         app: AppModule,
//     },
// });
