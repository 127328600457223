<template>
    <transition v-if="wanadevLoaderController" name="fade">
        <div v-if="wanadevLoaderController && wanadevLoaderController.isVisible" id="wanadev-loader-container">
            <div class="loader-container play">
                <svg
                    id="Calque_1"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 165.2 44"
                    style="enable-background: new 0 0 165.2 44"
                    xml:space="preserve"
                >
                    <path
                        class="st0"
                        d="M17.4,5.5c-1.2,1.1-2.5,2.2-3.4,3.2l-0.1,0.1v3.6c1.4-1.2,5.7-4.8,5.7-4.8h0.6c2.5,0,6.7,0,11.2,1.6l0.5,0.2
                    L31,15.3L30.9,16l-2.2-0.5c-0.5-0.1-1.2-0.3-1.6-0.4c0,0.8,0,2.2,0,2.2v0.4l-2.1,1c0,0-0.2,0.1-0.4,0.2c0,0.5,0,2,0,2l-0.3,0.2
                    c-0.2,0.1-0.4,0.2-0.7,0.3c0,0.2,0,3.4,0,3.4S22.4,25,22,25.1c0,0.2,0.2,5.9,0.2,5.9l-0.4,0.2c-3.9,1.8-6.9,3.6-8.9,5.3v1.9h32.8
                    v-33L17.4,5.5"
                    />
                    <g>
                        <path
                            d="M19.5,7.5L13,13V8.2c-5.7,0.7-9.2,2.3-12.8,4.3v5.7c0.5-0.3,3.6-1.7,4.9-2.3v3.4c0.3,0,4.4,0.2,5.7,0.1
                        c-0.9,0.3-3.2,0.7-4.3,0.7l-0.1,4.4c0.5-0.2,4.7-0.9,4.7-0.9l-2.6,1.5l-0.1,5l4-0.2L11.2,31v5.3c2.8-3.1,8-5.6,10.1-6.5l-0.1-5.1
                        c-0.2,0.1-2.1,0.2-3.4,0.2l5-1.7v-2.6h-1.4v-0.3c0,0,1.9-0.6,2.4-0.9v-2c0.2-0.1,1.7-0.7,2.4-1.1v-2.9c0.5,0,3,0.7,3.9,0.9L30.9,9
                        C26.2,7.4,21.9,7.5,19.5,7.5z M23.9,13.3C19.3,15.4,11.7,16,6,15.6C12.2,13,20.5,13,23.9,13.3z"
                        />
                        <path d="M20.4,1.7V1.1h-7.1v6.5C15.3,5.4,18.4,2.7,20.4,1.7z" />
                        <path d="M46.6,9.5c2.2,0.1,2.9,0.8,2.9,3.5v21.7h3.9v-27l-6.8,1V9.5z" />
                        <path
                            d="M65.3,16.4c-5.2,0-8.1,2.6-8.1,8.7c0,6.4,3.5,10,9.4,10c2.6,0,4.7-0.7,5.4-1c0,0,0-1.7,0-1.7c-0.6,0.3-2,0.7-3.6,0.7
                        c-5.4,0-7-5.4-7-9.3h11C72.4,21.2,71.9,16.4,65.3,16.4z M61.4,22.2c0-1.5,0.8-4.5,3.7-4.5c3.3,0,3.4,3.6,3.4,4.5H61.4z"
                        />
                        <path
                            d="M86.7,19h-0.1c-0.6-1.1-2-2.7-4.8-2.7c-5.3,0-6.8,4.8-6.8,9.8c0,4.6,1.9,8.6,6.8,8.6c3.2,0,4.2-1.5,4.9-2.7h0.1v4.5
                        c0,2.1,0,5.1-3.7,5.1c-3.1,0-3.6-2.5-3.6-4.1h-4.4c0,4.3,3.4,5.4,8.1,5.4c6.8,0,7.5-3.6,7.5-7.6l-0.1-15c0-2.5,1.8-2.7,3-2.7v-0.8
                        h-6.9V19z M86.7,27.1c0,2.1-0.6,5.5-3.9,5.5c-3.1,0-3.5-4.3-3.5-7c0-2.8,0.4-7.2,3.9-7.2c2.4,0,3.6,2.5,3.6,4.9L86.7,27.1z"
                        />
                        <path
                            d="M101.8,16.4c-5.5,0-6.8,2.7-7.2,5.2h4.6c0-1.7,0.2-3.9,2.7-3.9c2.4,0,2.4,2.7,2.4,5.1v2c-2.4,0-10.1,0.5-10.1,5.9
                        c0,2.2,2.2,4.4,5.2,4.4c2.7,0,4.1-1.6,4.8-2.5v2.1h3.9V23C108.1,19.2,107.3,16.4,101.8,16.4z M104.4,28.4c0,2.7-1.4,4.7-3.6,4.7
                        c-1.4,0-2.3-1.2-2.3-3c0-3.7,4.5-3.9,5.9-3.9V28.4z"
                        />
                        <path d="M109.9,9.5c2.2,0.1,2.9,0.8,2.9,3.5v21.7h3.9v-27l-6.8,1V9.5z" />
                        <path d="M118.2,9.5c2.2,0.1,2.9,0.8,2.9,3.5v21.7h3.9v-27l-6.8,1V9.5z" />
                        <path
                            d="M135.2,16.4c-5.5,0-6.8,2.7-7.2,5.2h4.6c0-1.7,0.2-3.9,2.7-3.9c2.4,0,2.4,2.7,2.4,5.1v2c-2.4,0-10.1,0.5-10.1,5.9
                        c0,2.2,2.2,4.4,5.2,4.4c2.7,0,4.1-1.6,4.8-2.5v2.1h3.9V23C141.5,19.2,140.7,16.4,135.2,16.4z M134.2,33.1c-1.4,0-2.3-1.2-2.3-3
                        c0-3.7,4.5-3.9,5.9-3.9l0,2.2C137.8,31.1,136.4,33.1,134.2,33.1z"
                        />
                        <path d="M143.3,18.4c2.4,0,3,0.7,3,3.4l-0.1,12.9h3.9V16.4l-6.8,1.2V18.4z" />
                        <path
                            d="M148.3,8.5c-1.3,0-2.4,1.1-2.4,2.4c0,1.4,1.1,2.4,2.4,2.4s2.5-1.1,2.5-2.4C150.8,9.5,149.6,8.5,148.3,8.5z"
                        />
                        <path
                            d="M156.5,20.2c0-1.6,1.5-2.1,2.7-2.1c2.2,0,4,1.4,4.6,1.8l0-2.9c-0.6-0.2-2.3-0.7-4.3-0.7c-4.5,0-6.4,2.4-6.4,5.2
                        c0,5.3,8.4,5.5,8.4,9.3c0,1.4-0.9,2.6-3.3,2.6c-2.2,0-4-1.2-5.1-2v3c0.9,0.2,2.6,0.7,4.9,0.7c3.8,0,6.9-1.5,6.9-5.3
                        C164.9,23.9,156.5,23.9,156.5,20.2z"
                        />
                    </g>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
import self from "../index";

export default {
    name: "wanadev-loader",
    data() {
        return {
            wanadevLoaderController: null,
        };
    },
    mounted() {
        this.wanadevLoaderController = self.wanadevLoaderController;
    },
};
</script>

<style lang="scss">
.st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffe000;
}

#wanadev-loader-container {
    @apply bg-gray-300;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;

    .loader-container {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader-container svg {
        height: 200px;
    }
}

.fade-leave-active {
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.fade-leave-to {
    opacity: 0;
}
</style>
