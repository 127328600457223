<template>
    <div
        v-if="isConfiguratorReady && !isLoading"
        class="col-span-1 mb-2 flex w-full flex-col justify-start bg-gray-100 px-0.5 pb-0.5"
    >
        <ul class="flex flex-wrap items-center justify-around md:flex-nowrap lg:grid lg:grid-cols-4">
            <li :class="[{ 'active': isActive(1) }, 'tab-button']" @click.prevent="setActive(1)">
                1. Caractéristiques
            </li>
            <li :class="[{ 'active': isActive(2) }, 'tab-button']" @click.prevent="setActive(2)">
                2. Personnalisation
            </li>
            <li :class="[{ 'active': isActive(3) }, 'tab-button']" @click.prevent="setActive(3)">3. Finition</li>
            <li :class="[{ 'active': isActive(4) }, 'tab-button']" @click.prevent="setActive(4)">4. Quantité</li>
        </ul>

        <div class="mx-auto h-full w-full bg-white px-6 pb-6 pt-8">
            <div v-if="isActive(1)">
                <Characteristics />
            </div>
            <div v-if="isActive(2)">
                <Personalization />
            </div>
            <div v-if="isActive(3)">
                <Finishing />
            </div>
            <div v-if="isActive(4)">
                <Quantity />
            </div>
        </div>
    </div>
</template>

<script>
import Characteristics from "./tabs/characteristics.vue";
import Finishing from "./tabs/finishing.vue";
import Personalization from "./tabs/personalization.vue";
import Quantity from "./tabs/quantity.vue";
import eventBus from "../services/eventBus";

export default {
    name: "tabs",
    components: {
        Characteristics,
        Personalization,
        Finishing,
        Quantity,
    },
    data() {
        return {
            activeItemIndex: 1,
        };
    },
    mounted() {
        eventBus.on("setTab", (tabIndex) => {
            this.setActive(tabIndex);
        });
    },
    computed: {
        isLoading() {
            return this.$store.getters["project/isLoading"];
        },
        isConfiguratorReady() {
            return this.$store.getters["project/isConfiguratorReady"];
        },
    },
    methods: {
        isActive(menuItemIndex) {
            return this.activeItemIndex === menuItemIndex;
        },
        setActive(menuItemIndex) {
            this.activeItemIndex = menuItemIndex;
        },
    },
};
</script>

<style lang="scss">
.tab-button {
    @apply cursor-pointer border-t-2 border-transparent px-2 py-2 text-center text-sm text-black xl:px-4;
    &.active {
        @apply border-theme-yellow bg-white font-bold;
    }
}
</style>
