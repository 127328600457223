<template>
    <div class="content ml-10 text-sm">
        <div class="mb-4">
            <div>
                <input
                    :id="`image-${locationId}`"
                    type="radio"
                    :name="`type-${locationId}`"
                    :checked="isArticleCustomType('image')"
                    @click="selectType('image')"
                />
                <label :for="`image-${locationId}`" class="font-semibold">Image</label>
                <span class="italic">{{
                    isArticleCustomType("image") ? getSourceFileName(customization.source) : ""
                }}</span>
            </div>
            <div :class="[selectedType === 'image' ? 'flex' : 'hidden', 'ml-8 flex flex-col']">
                <div class="text-theme-blue">
                    <label :for="`image-file-${locationId}`" class="cursor-pointer text-theme-blue underline">
                        {{
                            `${isArticleCustomType("image") && customization.source ? "Remplacer le fichier" : "Ajouter un fichier"} ...`
                        }}
                    </label>
                    <input
                        :id="`image-file-${locationId}`"
                        type="file"
                        class="hidden"
                        @change="setOptionFile($event, locationId)"
                    />
                    <div v-if="imageError.length > 0" class="text-red-500">
                        {{ imageError }}
                    </div>
                    <div v-if="imageWarning.length > 0" class="text-theme-orange">
                        {{ imageWarning }}
                    </div>
                </div>
                <div v-if="isArticleCustomType('image') && customization.location" class="mt-4">
                    <input
                        :id="`image-file-background-${locationId}`"
                        type="checkbox"
                        :checked="customization.hideImageBackground"
                        @change="setBackgroundImageOption"
                    />
                    <label :for="`image-file-background-${locationId}`" class="cursor-pointer">
                        Masquer le fond de l'image
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <div>
                <input
                    :id="`text-${locationId}`"
                    type="radio"
                    :name="`type-${locationId}`"
                    :checked="isArticleCustomType('text')"
                    @click="selectType('text')"
                />
                <label :for="`text-${locationId}`" class="font-semibold">Texte</label>
            </div>
            <div :class="[selectedType === 'text' ? 'flex' : 'hidden', 'ml-8 flex flex-col']">
                <div class="mt-4">
                    <div class="mb-4 flex flex-col">
                        <label :for="`text-input-${locationId}`" class="text-gray-500">Texte</label>
                        <textarea
                            :id="`text-input-${locationId}`"
                            ref="text-input"
                            rows="2"
                            cols="5"
                            class="w-40 rounded-sm border-2 border-gray-300 px-2 py-1"
                            :value="isArticleCustomType('text') ? customization.content.value : ''"
                            @keyup="setOptionText($event)"
                        />
                    </div>
                    <div class="mb-4 flex flex-col">
                        <label :for="`text-color-${locationId}`" class="text-gray-500">Style</label>
                        <select
                            :id="`text-color-${locationId}`"
                            class="w-40 rounded-sm border-2 border-gray-300 bg-white px-2 py-1 font-bold"
                            @change="setOptionStyle($event)"
                        >
                            <option
                                v-for="(label, value) in getTextStyleOptions()"
                                :key="value"
                                :value="value"
                                :selected="isArticleCustomType('text') && customization.content.style === value"
                            >
                                {{ label }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <span class="text-gray-500">Couleur police</span>
                        <div class="flex flex-row flex-wrap items-end">
                            <div
                                v-for="option in getTextColorOptions()"
                                :key="option.color"
                                :class="[
                                    {
                                        active:
                                            isArticleCustomType('text') && customization.content.color === option.color,
                                    },
                                    'option-selector mb-2',
                                ]"
                                @click="setOptionColor(option.color)"
                            >
                                <span :class="['picker h-10 w-10', option.class]" />
                                <span class="whitespace-nowrap text-sm">{{ option.label }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";

import {
    calculateDPI,
    getDefaultImageCustomization,
    getDefaultTextCustomization,
    getFilenameExtension,
    getFilenameWithoutExtension,
    getFileTypeAuthorized,
    getSourceFileName,
    getTextColorOptions,
    getTextStyleOptions,
} from "../../../helpers/customizationHelper";
import { getLocationDetails } from "../../../helpers/locationHelper";
import { FILE_TYPE_ASSET, FILE_TYPES } from "../../../services/constants";

export default {
    name: "customization-location",
    props: {
        locationId: {
            type: String,
            default: null,
        },
    },
    emits: ["customization-update"],
    data() {
        return {
            selectedOptions: [],
            selectedType: null,
            getTextStyleOptions,
            getTextColorOptions,
            imageError: "",
            imageWarning: "",
            minimumDpi: 100,
        };
    },
    computed: {
        article() {
            return this.$store.getters["project/currentArticle"];
        },
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
        customization() {
            return this.article.customization.find((custom) => custom.location === this.locationId);
        },
    },
    watch: {
        customization() {
            if (this.customization) {
                this.selectedType = this.customization.type;
            }
            this.selectedType = this.customization ? this.customization.type : null;
        },
    },
    created() {
        if (this.customization) {
            this.selectedType = this.customization.type;
        }
    },
    methods: {
        getSourceFileName,
        selectType(type) {
            this.selectedType = type;
            if (type === "text") {
                this.$refs["text-input"].focus();
            }
        },
        setOptionText: debounce(function callback(event) {
            const { value } = event.target;

            if (this.customization === undefined || this.customization.type !== "text") {
                this.setTextOptionDefault(value);
            } else {
                this.setOption(value, "value", true);
            }
        }, 700),
        setOptionStyle(event) {
            const { value } = event.target;
            this.setOption(value, "style", true);
        },
        setOptionColor(value) {
            this.setOption(value, "color", true);
        },
        async setOptionFile(event, locationId) {
            this.imageError = "";
            this.imageWarning = "";
            const file = event.target.files[0];

            if (!getFileTypeAuthorized().includes(file.type)) {
                this.imageError = "Ce type de fichier n'est pas pris en compte";
            }

            const fileSize = file.size;

            if ((fileSize / 1024 / 1024).toFixed(4) > 10) {
                this.imageError = "Le fichier sélectionné est trop lourd (10Mo maximum).";
            }

            if (this.imageError === "") {
                const filenameExtension = getFilenameExtension(file.name);
                const filenameWithoutExtension = getFilenameWithoutExtension(file.name);
                const fileName = `${filenameWithoutExtension}-${locationId}.${filenameExtension}`;

                const res = await this.$store.dispatch("project/uploadFile", {
                    file: file,
                    type: FILE_TYPES[FILE_TYPE_ASSET],
                    fileName: fileName,
                });
                if (res.status === 500) {
                    this.imageError = "Une erreur s'est produite lors de l'envoie de l'image. Veuillez réessayer.";
                }
                const { url } = res.data;

                const image = new Image();
                image.src = url;
                image.onload = () => {
                    const locationDetail = getLocationDetails(this.locationId);
                    const imageDpiW = calculateDPI(image.width, locationDetail.dimension.width * 10);
                    const imageDpiH = calculateDPI(image.height, locationDetail.dimension.height * 10);

                    if (imageDpiW < this.minimumDpi || imageDpiH < this.minimumDpi) {
                        this.imageWarning =
                            "La qualité de l'image fournie est insuffisante pour une excellente impression";
                    }
                };

                if (this.customization === undefined || this.customization.type !== "image") {
                    this.setImageOptionDefault(url);
                } else {
                    this.setOption(url, "source");
                }
            }
        },
        setBackgroundImageOption(event) {
            this.setOption(event.target.checked, "hideImageBackground");
        },
        setOption(value, prop, isContentValue = false) {
            const customization = JSON.parse(JSON.stringify(this.customization));

            if (isContentValue) {
                customization.content[prop] = value;
            } else {
                customization[prop] = value;
            }

            this.$emit("customization-update", customization);
        },
        setImageOptionDefault(value) {
            const customization = getDefaultImageCustomization();

            customization.location = this.locationId;
            customization.source = value;
            customization.hideImageBackground = false;

            this.$emit("customization-update", customization);
        },
        setTextOptionDefault(value) {
            const customization = getDefaultTextCustomization();

            customization.location = this.locationId;
            customization.content.value = value;

            this.$emit("customization-update", customization);
        },
        isArticleCustomType(type) {
            if (this.customization === undefined) {
                return false;
            }
            return this.customization.type === type;
        },
    },
};
</script>

<style lang="scss">
.option-selector {
    @apply mr-4 flex cursor-pointer flex-col items-center;
    .picker {
        @apply flex items-center justify-center rounded-sm border-2 border-gray-300 font-semibold text-gray-300;
    }

    &.active {
        @apply font-semibold text-black;
        .picker {
            @apply border-black text-black;
        }
    }
}
</style>
