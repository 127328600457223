import catalog from "assets/data/products";

import self from "../../index";
import api from "../api/project";
import saveQuote from "../api/quotes";
import { ARTICLE_STATUS, FILE_TYPES, FILE_TYPE_ASSET } from "../services/constants";
import { getNewArticleId } from "../helpers/projectHelper";
import { getSourceFileName } from "../helpers/customizationHelper";
import deepClone from "../services/deepClone";

function getEditingArticleId(articles) {
    return articles.length > 0 ? articles.find((article) => article.status === Object.keys(ARTICLE_STATUS)[1]).id : 0;
}

const {
    modules: { iframeApi },
} = self.app;

export default {
    namespaced: true,
    state: {
        isLoading: false,
        loadingMessage: "Chargement du configurateur ...",
        error: null,
        project: { name: "Nouveau projet" },
        articles: [],
        currentArticleId: 0,
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },

        loadingMessage(state) {
            return state.loadingMessage;
        },

        hasError(state) {
            return state.error !== null;
        },

        error(state) {
            return state.error;
        },

        project(state) {
            return state.project;
        },

        articles(state) {
            return state.articles;
        },

        currentArticle(state) {
            return state.articles.find((article) => article.id === state.currentArticleId);
        },

        productConfiguration(state, getters) {
            return catalog.find((product) => product.reference === getters.currentArticle.reference);
        },
        isConfiguratorReady(state, getters) {
            return (
                getters.currentArticle !== undefined &&
                getters.productConfiguration !== undefined &&
                getters.project.id !== undefined
            );
        },
    },
    mutations: {
        "INIT_STORE_PROJECT"(state) {
            state.isLoading = true;
            state.loadingMessage = "Chargement du configurateur ...";
            state.error = null;
            state.project = {};
            state.articles = [];
            state.currentArticleId = 0;
        },
        "INIT_PROJECT"(state, project) {
            state.isLoading = false;
            state.error = null;
            state.project = project;
            state.articles = project.articles;
        },
        "UPDATE_PROJECT"(state, project) {
            state.error = null;
            state.project = project;
            state.articles = project.articles;
        },
        "UPDATE_PROJECT_ERROR"(state, error) {
            state.error = error;
            state.project = {};
            state.articles = [];
            state.currentArticleId = 0;
        },
        "CHANGE_CURRENT_ARTICLE"(state, id) {
            state.currentArticleId = id;
        },
        "UPLOAD_FILE_ERROR"(state, error) {
            state.error = error;
            state.isLoading = false;
        },
        "REMOVE_ASSET_ERROR"(state, error) {
            state.error = error;
            state.isLoading = false;
        },
        "REMOVE_ASSETS_ERROR"(state, error) {
            state.error = error;
            state.isLoading = false;
        },
        "UPDATE_LOADER"(state, loader) {
            state.isLoading = loader.status;
            state.loadingMessage = loader.message;
        },
        "SAVE_QUOTE_ERROR"(state, error) {
            state.error = error;
            state.isLoading = false;
        },
        "GENERATE_BAT_ERROR"(state, error) {
            state.error = error;
            state.isLoading = false;
        },
    },
    actions: {
        async getProject({ commit }, projectId) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            commit("INIT_STORE_PROJECT");

            try {
                let data;
                if (projectId) {
                    const res = await api.getOne(STATIC_TOKEN, projectId);
                    const resJson = await res.json();
                    const { project } = resJson;
                    data = project;
                } else {
                    data = { name: "Nouveau projet", articles: [] };
                }
                await commit("INIT_PROJECT", data);
                await commit("CHANGE_CURRENT_ARTICLE", getEditingArticleId(data.articles));
            } catch (err) {
                commit("UPDATE_PROJECT_ERROR", err);
            }
        },
        async setCurrentArticle({ commit, state }, article) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            const project = { ...state.project };
            const articleIdx = project.articles.findIndex((art) => art.id === state.currentArticleId);

            project.articles[articleIdx] = article;

            commit("UPDATE_PROJECT", project);
            try {
                if (!project.id) {
                    const projectWithId = await api.post(STATIC_TOKEN, project);
                    const projectWithIdJson = await projectWithId.json();
                    commit("UPDATE_PROJECT", projectWithIdJson.project);
                } else {
                    await api.put(STATIC_TOKEN, project.id, project);
                }
            } catch (err) {
                commit("UPDATE_PROJECT_ERROR", err);
            }
            iframeApi.sendEvent("projectUpdated", JSON.parse(JSON.stringify(project)));
        },
        async updateCurrentArticle({ commit, state }, { prop, value }) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            const project = { ...state.project };
            const articleIdx = project.articles.findIndex((art) => art.id === state.currentArticleId);

            project.articles[articleIdx][prop] = value;

            commit("UPDATE_PROJECT", project);
            try {
                if (!project.id) {
                    const projectWithId = await api.post(STATIC_TOKEN, project);
                    const projectWithIdJson = await projectWithId.json();
                    commit("UPDATE_PROJECT", projectWithIdJson.project);
                } else {
                    await api.put(STATIC_TOKEN, project.id, project);
                }
            } catch (err) {
                commit("UPDATE_PROJECT_ERROR", err);
            }
            iframeApi.sendEvent("projectUpdated", JSON.parse(JSON.stringify(project)));
        },
        async addArticle({ commit, state }, article) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || "NO API KEY IN PROJECT.JS";
            const project = { ...state.project };
            let projectApi = {};
            project.articles.push(article);

            commit("UPDATE_PROJECT", project);
            commit("CHANGE_CURRENT_ARTICLE", article.id);
            try {
                if (!project.id) {
                    const projectWithId = await api.post(STATIC_TOKEN, project);
                    const projectWithIdJson = await projectWithId.json();
                    projectApi = projectWithIdJson.project;
                    commit("UPDATE_PROJECT", projectWithIdJson.project);
                } else {
                    const projectWithId = await api.put(STATIC_TOKEN, project.id, project);
                    const projectWithIdJson = await projectWithId.json();
                    projectApi = projectWithIdJson.project;
                }
            } catch (err) {
                commit("UPDATE_PROJECT_ERROR", err);
            }
            iframeApi.sendEvent("projectUpdated", projectApi);
        },
        async duplicateArticle({ dispatch, state }, articleId) {
            const articleToDuplicate = state.articles.find((art) => art.id === articleId);
            const newArticle = deepClone(articleToDuplicate);
            newArticle.id = getNewArticleId();

            await dispatch('addArticle', newArticle);

            // Duplicate assets
            await Promise.all(newArticle.customization.map(async (custom) => {
                if (custom.type === 'image') {
                    const assetFile = await fetch(custom.source);
                    const assetFileBlob = await assetFile.blob();

                    const uploadedFile = await dispatch('uploadFile', {
                        file: assetFileBlob,
                        type: FILE_TYPES[FILE_TYPE_ASSET],
                        fileName: getSourceFileName(custom.source),
                    })
                    custom.source = uploadedFile.data.url;
                }
            }));
        },
        async deleteArticle({ commit, state }, articleId) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            const project = { ...state.project };
            project.articles = project.articles.filter((art) => art.id !== articleId);

            commit("UPDATE_PROJECT", project);

            if (state.currentArticleId === articleId) {
                const nextid = getEditingArticleId(project.articles);
                commit("CHANGE_CURRENT_ARTICLE", nextid);
            }

            try {
                await api.put(STATIC_TOKEN, project.id, project);
            } catch (err) {
                commit("UPDATE_PROJECT_ERROR", err);
            }
            iframeApi.sendEvent("projectUpdated", project);
        },
        async updateProjectName({ commit, state }, projectName) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            const project = { ...state.project };
            project.name = projectName;

            commit("UPDATE_PROJECT", project);
            try {
                if (project.id) {
                    await api.put(STATIC_TOKEN, project.id, project);
                }
            } catch (err) {
                commit("UPDATE_PROJECT_ERROR", err);
            }
            iframeApi.sendEvent("projectUpdated", JSON.parse(JSON.stringify(project)));
        },
        async uploadFile({ commit, state, getters }, payload) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            let data = null;

            try {
                const res = await api.uploadFile(STATIC_TOKEN, state.project.id, getters.currentArticle.id, payload);
                data = await res.json();
                data.status = res.status;
            } catch (err) {
                commit("UPLOAD_FILE_ERROR", err);
            }

            return data;
        },
        removeAsset({ commit, state, getters }, location) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;

            try {
                api.removeAsset(STATIC_TOKEN, state.project.id, getters.currentArticle.id, location);
            } catch (err) {
                commit("REMOVE_ASSET_ERROR", err);
            }
        },
        removeAssets({ commit, state }, articleId) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;

            try {
                api.removeAsset(STATIC_TOKEN, state.project.id, articleId);
            } catch (err) {
                commit("REMOVE_ASSETS_ERROR", err);
            }
        },
        async generateArticleBat({ commit, state }, payload) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;

            try {
                await api.generateArticleBat(STATIC_TOKEN, state.project.id, payload);
            } catch (err) {
                commit("GENERATE_BAT_ERROR", err);
            }
        },
        async saveQuote({ commit, state }, payload) {
            const STATIC_TOKEN = process.env.VUE_APP_API_KEY || false;
            let data = null;
            try {
                commit("UPDATE_LOADER", true);
                const res = await saveQuote(STATIC_TOKEN, state.project.id, payload);
                data = await res.data;
            } catch (err) {
                commit("SAVE_QUOTE_ERROR", err);
            }
            commit("UPDATE_LOADER", false);
            return data;
        },
    },
};
