import { v4 as uuidv4 } from "uuid";

import store from "../store";
import catalog from "../../../../assets/data/products";

function getImagesRelatedColor(article) {
    const productCatalog = catalog.find((product) => product.reference === article.reference);

    return productCatalog.images
        .filter((image) => image.carouselColor === article.color)
        .map((image) => image.url.replace("assets/", ""));
}

export default function generateBats(articles) {
    try {
        let promise = Promise.resolve();
        for (let i = 0; i <= articles.length - 1; i++) {
            promise = promise.then(
                () =>
                    new Promise((resolve) => {
                        articles[i].id_bat = uuidv4();
                        articles[i].images = getImagesRelatedColor(articles[i]);
                        store.dispatch("project/generateArticleBat", { ...articles[i] });
                        resolve();
                    }),
            );
        }

        return promise.then(() => articles);
    } catch (error) {
        throw new Error(error);
    }
}
