<template>
    <transition name="modal">
        <div
            class="fixed left-0 top-0 z-50 table h-full w-full bg-black bg-opacity-50 transition-opacity duration-300 ease-linear"
        >
            <div class="table-cell align-middle">
                <div
                    v-click-out-side="close"
                    class="mx-auto my-0 w-3/4 rounded-sm bg-white px-7 py-5 shadow-lg transition-all duration-300"
                >
                    <div class="mt-0 flex items-center justify-between">
                        <h3 v-if="title">
                            {{ title }}
                        </h3>
                        <button type="button" class="btn-close" @click="close">
                            <i class="icon-cross" />
                        </button>
                    </div>

                    <div class="mx-0 my-5">
                        <slot name="body" />
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="modal-default-button" @click="close">Fermer</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "modal",
    props: {
        title: {
            type: String,
            default: null,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>
