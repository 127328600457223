import locations from "assets/data/locations.json";

function getLocationDetails(locationId) {
    return locations.find((location) => location.id === locationId);
}

function getLocationName(locationId) {
    return getLocationDetails(locationId).name;
}

export { getLocationDetails, getLocationName };
