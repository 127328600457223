export const VAT_RATE = 5.5;

export const CURRENCY = "€";
export const CURRENCY_NAME = "EUR";

export const PRODUCT_FINISHING_EMBROIDERY = "EMBROIDERY";
export const PRODUCT_FINISHING_PRINTING = "PRINTING";
export const PRODUCT_FINISHING = {
    [PRODUCT_FINISHING_EMBROIDERY]: "Broderie",
    [PRODUCT_FINISHING_PRINTING]: "Transfert numérique",
};

export const ARTICLE_STATUS_FINISH = "FINISH";
export const ARTICLE_STATUS_EDITING = "EDITING";
export const ARTICLE_STATUS_NOT_FINISH = "NOT_FINISH";
export const ARTICLE_STATUS = {
    [ARTICLE_STATUS_FINISH]: "Terminé",
    [ARTICLE_STATUS_EDITING]: "Édition en cours",
    [ARTICLE_STATUS_NOT_FINISH]: "Non terminé",
};

export const CUSTOMIZATION_TYPE_TEXT = "text";
export const CUSTOMIZATION_TYPE_IMAGE = "image";
export const CUSTOMIZATION_TYPES = {
    [CUSTOMIZATION_TYPE_TEXT]: "Texte",
    [CUSTOMIZATION_TYPE_IMAGE]: "Image",
};

export const FILE_TYPE_BAT = "FILE_TYPE_BAT";
export const FILE_TYPE_ASSET = "FILE_TYPE_ASSET";
export const FILE_TYPES = {
    [FILE_TYPE_BAT]: "bat",
    [FILE_TYPE_ASSET]: "asset",
};
