<!-- Main Vue for the obsidian app -->

<template>
    <!-- HTML template -->
    <div id="app" ref="app" class="container max-w-full">
        <Loader />
        <Project />

        <div class="grid grid-cols-2">
            <ArticleInfo />
            <div v-if="isConfiguratorReady" class="flex flex-col">
                <Carousel />
            </div>
            <Tabs />
        </div>

        <Catalog v-if="!isConfiguratorReady" />
    </div>
</template>

<script>
import self from "../../index";
import Catalog from "./catalog.vue";
import ArticleInfo from "./common/article-info.vue";
import Loader from "./common/loader.vue";
import Carousel from "./carousel.vue";
import Project from "./project.vue";
import Tabs from "./tabs.vue";

const { modules } = self.app;

export default {
    name: "app",
    components: {
        Catalog,
        Project,
        ArticleInfo,
        Tabs,
        Loader,
        Carousel,
    },
    computed: {
        isConfiguratorReady() {
            return this.$store.getters["project/isConfiguratorReady"];
        },
        currentArticle() {
            return this.$store.getters["project/currentArticle"];
        },
    },
    created() {
        const { projectId } = modules.iframeApi.$class.$getConfig();
        this.$store.dispatch("project/getProject", projectId);
    },
    mounted() {
        const height = this.$refs.app.offsetHeight;
        modules.iframeApi.sendEvent("initHeight", height);
    },
};
</script>

<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.canvas-container {
    /* Tricks to maintain an aspect ratio between width and height */
    height: 0;
    padding-bottom: 60%;
    @apply relative col-span-1 w-full;
}
</style>
