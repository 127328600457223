export default {
    namespaced: true,
    state: {
        isAppStarted: false,
        positionList: [],
    },
    getters: {
        isAppStarted(state) {
            return state.isAppStarted;
        },
        getPositionList(state) {
            return state.positionList;
        },
    },
    mutations: {
        "START_APP"(state) {
            state.isAppStarted = true;
        },
        "SET_POSITION_LIST"(state, positions) {
            state.positionList = positions;
        },
    },
    actions: {
        startApp({ commit }) {
            commit("START_APP");
        },
        setPositionList({ commit }, positions) {
            commit("SET_POSITION_LIST", positions);
        },
    },
};
