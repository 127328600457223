import PRODUCT_000436_0104 from "./000436-0104.json"; // Pantalon de travail
import PRODUCT_001221_0379 from "./001221-0379.json"; // Parka haute visibilité
import PRODUCT_20910 from "./20910.json"; // Softshell
import PRODUCT_20915 from "./20915.json"; // Softshell femme
import PRODUCT_021000 from "./021000.json"; // Sweat sans capuche
import PRODUCT_21002 from "./21002.json"; // Sweat à capuche
import PRODUCT_021004 from "./021004.json"; // Veste à capuche
import PRODUCT_CGTU03T from "./CGTU03T.json"; // T-shirt manches courtes (185g)
import PRODUCT_CGTU01T from "./CGTU01T.json"; // T-shirt manches courtes (145g)
import PRODUCT_CGTU07T from "./CGTU07T.json"; // T-shirt manches longues
import PRODUCT_CGTW04T from "./CGTW04T.json"; // T-shirt manches courtes femme (185g)
import PRODUCT_CGTW02T from "./CGTW02T.json"; // T-shirt manches courtes femme (145g)
import PRODUCT_CSC63402 from "./CSC63402.json"; // Polo manches courtes
import PRODUCT_HVW100 from "./HVW100.json"; // Gilet haute visibilité
import PRODUCT_K911 from "./K911.json"; // Veste polaire
import PRODUCT_KP031 from "./KP031.json"; // Bonnet
import PRODUCT_KP124 from "./KP124.json"; // Casquette
import PRODUCT_R127 from "./R127.json"; // Gilet sans manche

function products() {
    return [
        PRODUCT_CGTU03T, // T-shirt
        PRODUCT_CGTU01T,
        PRODUCT_CGTU07T,
        PRODUCT_CGTW04T,
        PRODUCT_CGTW02T,
        PRODUCT_CSC63402, // Polo
        PRODUCT_21002, // Sweat
        PRODUCT_021000,
        PRODUCT_K911, // Veste
        PRODUCT_20910,
        PRODUCT_20915,
        PRODUCT_R127,
        PRODUCT_021004,
        PRODUCT_HVW100, // HV
        PRODUCT_001221_0379,
        PRODUCT_000436_0104, // Pantalon
        PRODUCT_KP031, // Accessoires
        PRODUCT_KP124,
    ];
}

export default products();

export { products };
