import { createApp } from "vue";

import { vOnClickOutside } from "@vueuse/components";
import App from "./components/app.vue";
import store from "./store";

const self = require("../index").default;

const { modules } = self.app;

/**
 * Instanciate the main Vue, on the app element
 * @type {Boolean}
 */
export default class VuejsController {
    constructor() {
        const resizeObserver = new ResizeObserver((entries) => {
            modules.iframeApi.sendEvent("updateHeight", entries[0].target.offsetHeight);
        });

        setTimeout(() => {
            resizeObserver.observe(document.querySelector("#app"));
        }, 200);

        // Data binding and vue creation
        this.app = createApp(App);
        this.app.directive("click-out-side", vOnClickOutside);
        this.app.use(store);
        this.app.config.globalProperties.$imageRootPath = "assets/images/";
        this.app.mount("#app");
    }
}
