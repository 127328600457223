function getTextStyleOptions() {
    return {
        Arial: "Arial",
        Bodoni: "Bodoni",
        Script: "Script MT Bold",
        English: "English 111 Presto BT",
        Swiss: "Swiss 721 CN BT",
    };
}

function getTextColorOptions() {
    return [
        {
            color: "black",
            label: "Noir",
            class: "bg-font-black",
        },
        {
            color: "white",
            label: "Blanc",
            class: "bg-font-white",
        },
        {
            color: "gray",
            label: "Gris",
            class: "bg-font-gray",
        },
        {
            color: "blue",
            label: "Bleu",
            class: "bg-font-blue",
        },
        {
            color: "light-blue",
            label: "Bleu clair",
            class: "bg-font-light-blue",
        },
        {
            color: "navy",
            label: "Marine",
            class: "bg-font-navy",
        },
        {
            color: "red",
            label: "Rouge",
            class: "bg-font-red",
        },
        {
            color: "green",
            label: "Vert",
            class: "bg-font-green",
        },
        {
            color: "orange",
            label: "Orange",
            class: "bg-font-orange",
        },
        {
            color: "yellow",
            label: "Jaune",
            class: "bg-font-yellow",
        },
        {
            color: "purple",
            label: "Violet",
            class: "bg-font-purple",
        },
        {
            color: "brown",
            label: "Marron",
            class: "bg-font-brown",
        },
    ];
}

function getDefaultTextCustomization() {
    return {
        location: null,
        type: "text",
        source: null,
        content: {
            value: null,
            style: "Arial",
            color: "white",
        },
        size: "medium",
    };
}

function getDefaultImageCustomization() {
    return {
        location: null,
        type: "image",
        source: null,
        content: null,
        size: "medium",
    };
}

function getSourceFileName(filePath) {
    return filePath.replace(/^.*[\\/]/, "");
}

function getFilenameExtension(filename) {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(filename)[1];
}

function getFilenameWithoutExtension(filename) {
    const extension = getFilenameExtension(filename);
    return filename.substr(0, filename.length - (extension.length + 1));
}

function getFileTypeAuthorized() {
    return [
        "image/bmp",
        "image/x-windows-bmp",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg",
        "image/svg+xml",
        "image/webp",
        "application/illustrator",
        "application/pdf",
        "application/postscript",
    ];
}

// https://pixelcalculator.com/fr
function calculateDPI(pixels, size) {
    return (pixels * 25.4) / size;
}

export {
    getTextStyleOptions,
    getTextColorOptions,
    getDefaultTextCustomization,
    getDefaultImageCustomization,
    getSourceFileName,
    getFilenameExtension,
    getFilenameWithoutExtension,
    getFileTypeAuthorized,
    calculateDPI,
};
