export default async function saveQuote(token, projectId, quoteComposition) {
    // Save data
    try {
        const url = `/projects/${projectId}/quote`;
        const res = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-KEY": token,
            },
            body: JSON.stringify(quoteComposition),
        });
        return await res.json();
    } catch (error) {
        throw new Error(error);
    }
}
