import "core-js/features/array/flat-map";
import "./style/index.scss";

import IframeApi from "@obsidianjs/iframe-api";
import obsidian from "@obsidianjs/obsidian";
import Sentry from "@obsidianjs/sentry";

import Vuejs from "./modules/vuejs";
import WanadevLoader from "./modules/wanadev-loader";

const sentryDSNKey = process.env.sentryDSNKey || "";

const app = obsidian("starter-app");

app.use(Vuejs);
app.use(WanadevLoader, {
    config: {
        showAtStart: true,
    },
});
app.use(IframeApi);
app.use(Sentry, {
    config: {
        dsnKey: sentryDSNKey,
        capturedLevels: ["error", "warning"],
        environment: process.env.NODE_ENV || "dev",
        release: process.env.VERSION,
    },
});
app.start();
