import self from "../index";

/**
 * WanadevLoader Controller module
 */
class WanadevLoaderController {
    constructor() {
        this.isVisible = self.app.config.get("showAtStart");
    }

    show() {
        this.isVisible = true;
    }

    hide() {
        this.isVisible = false;
    }
}

export default WanadevLoaderController;
