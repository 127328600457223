import { CURRENCY_NAME, VAT_RATE } from "../services/constants";

function formatPrice(price) {
    return price.toLocaleString("fr-FR", {
        style: "currency",
        currency: CURRENCY_NAME,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

function round(price, decimals = 2) {
    const factorOfTen = 10 ** decimals;
    return Math.round(price * factorOfTen) / factorOfTen;
}

function getVATAmount(price) {
    return (price * VAT_RATE) / 100;
}

function buyPriceOperation(totalPriceArticles, totalPriceCustomizations) {
    return totalPriceArticles + totalPriceCustomizations;
}

function costPriceOperation(buyPrice, multiplicator) {
    return buyPrice * multiplicator;
}

function sellPriceOperation(costPrice, marge) {
    return (costPrice * marge) / 100 + costPrice;
}

function removeMarkUpFromPrice(costPrice, marge) {
    return ((100 - marge) / 100) * costPrice
}

export { formatPrice, getVATAmount, round, buyPriceOperation, removeMarkUpFromPrice, costPriceOperation, sellPriceOperation };
