<template>
    <div>
        <div class="mb-2 text-xl font-bold">Choisissez vos tailles et quantités</div>
        <div class="mb-2">
            <div v-if="!isArticleProductQuantityValid(article) && article.finishing" class="text-sm text-red-500">
                La quantité totale minimum pour cette finition est de {{ minimumQuantityFinishing }}
            </div>
            <span class="font-bold">Tailles et Quantités</span>
            <span class="ml-8 cursor-pointer text-theme-blue underline" @click="openModal">Guide des tailles</span>
        </div>
        <div class="p-2">
            <div v-for="(price, size) in product.buyPrices" :key="size" class="mb-4 flex">
                <span :class="[{ 'text-gray-400': article.quantity[size] === 0 }, 'w-24 font-bold']">{{ size }}</span>
                <Counter :amount="article.quantity[size]" @update-amount="setSizeAmount($event, size)" />
            </div>
        </div>
        <div class="my-4 flex">
            <button
                type="button"
                class="flex border border-theme-blue bg-white px-4 py-2 uppercase text-theme-blue"
                @click="previousStep"
            >
                précédent
            </button>
        </div>

        <Modal v-if="showModal" :title="`Guide des tailles - ${product.designation}`" @close="closeModal">
            <template #body>
                <img class="mx-auto" :src="product.sizeGuide" alt="Guide des tailles" />
            </template>
            <template #footer />
        </Modal>

        <Recap />
    </div>
</template>

<script>
import { isArticleProductQuantityValid } from "../../helpers/projectHelper";
import { PRODUCT_FINISHING_EMBROIDERY } from "../../services/constants";
import Counter from "../common/counter.vue";
import Modal from "../common/modal.vue";
import Recap from "../recap.vue";
import eventBus from "../../services/eventBus";

export default {
    name: "quantity",
    components: {
        Counter,
        Modal,
        Recap,
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        article() {
            return this.$store.getters["project/currentArticle"];
        },
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
        minimumQuantityFinishing() {
            return this.article.finishing === PRODUCT_FINISHING_EMBROIDERY
                ? this.product.minQuantity.embroidery
                : this.product.minQuantity.printing;
        },
    },
    methods: {
        isArticleProductQuantityValid,
        setSizeAmount(event, size) {
            const quantity = { ...this.article.quantity };

            quantity[size] = event;
            this.$store.dispatch("project/updateCurrentArticle", { prop: "quantity", value: quantity });
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        previousStep() {
            eventBus.emit("setTab", 3);
        },
    },
};
</script>
