<template>
    <div
        v-if="isLoading"
        class="absolute z-50 flex h-screen w-screen items-center justify-center overflow-hidden bg-theme-blue uppercase text-white"
    >
        {{ loadingMessage }}
    </div>
</template>

<script>
export default {
    name: "loader",
    computed: {
        isLoading() {
            return this.$store.getters["project/isLoading"];
        },
        loadingMessage() {
            return this.$store.getters["project/loadingMessage"];
        },
    },
};
</script>
