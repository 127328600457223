<template>
    <div>
        <div class="mb-2 text-xl font-bold">Choisissez votre couleur</div>
        <div class="mb-2">
            <span class="font-bold">Coloris</span>
            <span>: {{ getColorLabel(article.color) }}</span>
        </div>
        <div class="mb-4 flex items-center">
            <div
                v-for="(color, index) in product.colors"
                :key="`color-${index}`"
                :class="[
                    isActiveColor(color) && 'border-black',
                    !isActiveColor(color) && 'border-transparent',
                    'mr-4 cursor-pointer rounded-full border-2 shadow-sm',
                ]"
                :title="getColorLabel(color)"
                @click="selectColor(color)"
            >
                <span
                    :class="[`${getTailwindColorModel(color)}`, 'block h-8 w-8 rounded-full border-4 border-white']"
                />
            </div>
        </div>
        <div v-show="product.grammageOptions" class="mb-2 font-bold">Grammage</div>
        <div v-if="product.grammageOptions" class="mb-4 flex items-center gap-2">
            <button
                v-for="grammageElement in product.grammageOptions"
                :class="[
                    'flex h-10 w-16 items-center justify-center border',
                    !article.grammage && 'border-gray-500 text-gray-600',
                    isActiveGrammage(grammageElement.value) && 'border-gray-900 bg-gray-900 text-theme-yellow',
                    !isActiveGrammage(grammageElement.value) && article.grammage && 'border-gray-200 text-gray-200',
                ]"
                :key="grammageElement"
                @click="selectGrammage(grammageElement.value)"
                :title="grammageElement"
            >
                {{ grammageElement.value }}
            </button>
        </div>
        <div class="mb-2 font-bold">Description</div>
        <div class="mb-4">
            <div v-for="(description, index) in product.description" :key="index">
                {{ description }}
            </div>
        </div>
        <div class="mb-2 font-bold">Caractéristiques</div>
        <div class="mb-4 border border-gray-200 bg-gray-100">
            <div
                v-for="(caracteristic, index) in product.characteristics"
                :key="index"
                class="grid grid-cols-3 border-b border-gray-200"
            >
                <span class="col-span-1 flex items-center py-2 pl-4 text-sm font-bold text-gray-700">
                    {{ caracteristic.label }}
                </span>
                <span class="col-span-2 flex w-full items-center justify-end bg-white py-2 pr-8">
                    {{ caracteristic.value }}
                </span>
            </div>
        </div>
        <div v-if="product.documentations.length > 1" class="mb-2 font-bold">Documentation</div>
        <div class="flex flex-col">
            <a
                v-for="(doc, index) in product.documentations"
                :key="index"
                :href="doc.link"
                target="_blank"
                class="flex items-center text-theme-blue"
            >
                <i class="icon-document" />
                <span class="underline">{{ doc.name }}</span>
            </a>
        </div>
        <div class="flex justify-end">
            <button type="button" class="flex bg-theme-blue px-4 py-2 uppercase text-white" @click="nextStep">
                suivant
            </button>
        </div>
    </div>
</template>

<script>
import productsCatalog from "assets/data/products";
import { getColorLabel, getTailwindColorModel } from "../../helpers/colorHelper";
import { switchProductGrammage } from "../../helpers/projectHelper";
import eventBus from "../../services/eventBus";

export default {
    name: "characteristics",
    data() {
        return {
            products: productsCatalog,
        };
    },
    computed: {
        article() {
            return this.$store.getters["project/currentArticle"];
        },
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
    },
    mounted() {
        this.selectColor(this.article.color);
    },
    methods: {
        getColorLabel,
        getTailwindColorModel,
        isActiveColor(color) {
            return this.article.color === color;
        },
        selectColor(color) {
            eventBus.emit("updateCarouselImage", color);
            this.$store.dispatch("project/updateCurrentArticle", { prop: "color", value: color });
        },
        isActiveGrammage(grammage) {
            return this.article.grammage === grammage;
        },
        selectGrammage(grammage) {
            if (grammage.value === this.article.grammage) {
                return;
            }
            const newArticle = switchProductGrammage(this.article, grammage);
            this.$store.dispatch("project/setCurrentArticle", newArticle);
        },
        nextStep() {
            eventBus.emit("setTab", 2);
        },
    },
};
</script>
