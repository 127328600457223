<template>
    <div v-if="isConfiguratorReady && !isLoading">
        <h4 class="mb-6">Récapitulatif</h4>
        <div class="my-2 ml-2 grid grid-cols-4">
            <div class="col-span-1 font-bold">Coloris</div>
            <div>{{ getColorLabel(article.color) }}</div>
        </div>
        <div class="my-2 ml-2 grid grid-cols-4">
            <div class="col-span-1 font-bold">Personnalisation</div>
            <div v-if="article.customization.length > 0">
                <div
                    v-for="customization in article.customization"
                    :key="customization.location"
                    class="col-span-2 col-start-2"
                >
                    {{ getLocationName(customization.location) }} - {{ CUSTOMIZATION_TYPES[customization.type] }}
                </div>
            </div>
            <div v-else class="font-bold text-theme-orange">Non défini</div>
        </div>
        <div class="my-2 ml-2 grid grid-cols-4">
            <div class="col-span-1 font-bold">Finition</div>
            <div :class="{ 'font-bold text-theme-orange': article.finishing === null }">
                {{ getFinishingLabel(article.finishing) }}
            </div>
        </div>
        <div class="my-2 ml-2 grid grid-cols-4">
            <div class="col-span-1 font-bold">Quantité</div>
            <div :class="['col-span-2', { 'font-bold text-theme-orange': article.quantity === null }]">
                <span v-for="(size, name, index) in article.quantity" :key="index">
                    {{
                        `${name.toUpperCase()} x ${size}${Object.keys(article.quantity).length !== index + 1 ? "|" : ""}`
                    }}
                </span>
            </div>
        </div>
        <div class="mt-6 flex">
            <button type="button" class="btn-action btn-white mr-4 h-8 w-48 text-sm" @click="addArticle()">
                Ajouter un article
            </button>
            <button
                type="button"
                :class="['btn-action btn-blue h-8 w-48 text-sm', { 'disable': !isProjectValid(project) }]"
                :disabled="!isProjectValid(project)"
                @click.prevent="createQuote()"
            >
                Créer un devis
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";

import { getColorLabel } from "../helpers/colorHelper";
import { getLocationName } from "../helpers/locationHelper";
import { getFinishingLabel, isProjectValid } from "../helpers/projectHelper";
import { CUSTOMIZATION_TYPES } from "../services/constants";
import generateQuote from "../services/quoteGenerator";

export default {
    name: "recap",
    data() {
        return {
            CUSTOMIZATION_TYPES,
        };
    },
    computed: {
        isLoading() {
            return this.$store.getters["project/isLoading"];
        },
        isConfiguratorReady() {
            return this.$store.getters["project/isConfiguratorReady"];
        },
        article() {
            return this.$store.getters["project/currentArticle"];
        },
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
        project() {
            return this.$store.getters["project/project"];
        },
    },
    methods: {
        ...mapMutations("project", ["CHANGE_CURRENT_ARTICLE", "UPDATE_LOADER"]),
        getColorLabel,
        getFinishingLabel,
        getLocationName,
        isProjectValid,
        addArticle() {
            this.CHANGE_CURRENT_ARTICLE(0);
        },
        createQuote() {
            if (isProjectValid(this.project)) {
                this.UPDATE_LOADER({ status: true, message: "Enregistrement du devis en cours ..." });
                generateQuote(this.project);
            }
        },
    },
};
</script>
