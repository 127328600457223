<template>
    <div>
        <div class="mb-2 text-xl font-bold">Sélectionnez votre finition</div>
        <div
            :class="[
                'mb-2 flex flex-col items-center rounded-sm border p-2 lg:flex-row',
                article.finishing === PRODUCT_FINISHING_PRINTING && 'border-theme-blue bg-theme-blue-100',
                article.finishing !== PRODUCT_FINISHING_PRINTING && 'border-transparent',
                isPrintingDisabled && 'cursor-not-allowed',
                !isPrintingDisabled && 'cursor-pointer',
            ]"
            @click="selectFinishing(PRODUCT_FINISHING_PRINTING)"
        >
            <img
                src="assets/images/finition_numerique.jpg"
                alt="Finition transfert numérique"
                :class="['h-auto w-44', isPrintingDisabled && ' opacity-50']"
            />
            <div class="ml-4">
                <div :class="['col-span-7', isPrintingDisabled && ' opacity-50']">
                    <span class="font-bold">Transfert numérique</span>
                </div>
                <div :class="['col-span-7 row-span-2', isPrintingDisabled && ' opacity-50']">
                    <ul class="ml-6 list-outside list-none xl:ml-0 xl:list-inside">
                        <li>+ Impression fidèle du logo ou texte à reproduire.</li>
                        <li>+ Idéale pour des visuels multicolores et avec des dégradés de couleurs.</li>
                        <li>+ Très bonne résistance au lavage (jusqu’à 90°).</li>
                        <li>+ Possible sur tous les types de vêtements*.</li>
                        <li class="mt-4">- Le résultat final dépend de la qualité du visuel fourni.</li>
                        <li class="">- *Non disponible pour des vestes polaires.</li>
                    </ul>
                </div>
                <span v-if="isPrintingDisabled && unavailableLocationsForPrining.length > 0" class="text-red-600">
                    Cette finition n'est pas disponible pour ces emplacements :
                    {{ unavailableLocationsForPrining.join(", ") }}
                </span>
            </div>
        </div>
        <div
            :class="[
                'mb-2 flex flex-col items-center rounded-sm border  p-2 lg:flex-row',
                article.finishing === PRODUCT_FINISHING_EMBROIDERY && 'cursor-auto border-theme-blue bg-theme-blue-100',
                article.finishing !== PRODUCT_FINISHING_EMBROIDERY && 'border-transparent',
                isEmbroideryDisabled && 'cursor-not-allowed',
                !isEmbroideryDisabled && 'cursor-pointer',
            ]"
            @click="selectFinishing(PRODUCT_FINISHING_EMBROIDERY)"
        >
            <img
                src="assets/images/finition_broderie.jpg"
                alt="Finition broderie"
                :class="['h-auto w-44', isEmbroideryDisabled && ' opacity-50']"
            />
            <div class="ml-4">
                <div :class="['col-span-7', isEmbroideryDisabled && ' opacity-50']">
                    <span class="font-bold">Broderie</span>
                </div>
                <div :class="['col-span-7 row-span-2', isEmbroideryDisabled && ' opacity-50']">
                    <ul class="ml-6 list-outside list-none xl:ml-0 xl:list-inside">
                        <li>+ Assure une longue durée de vie à la personnalisation.</li>
                        <li>+ Excellente résistance au lavage (jusqu’à 95°).</li>
                        <li>+ Rendu très qualitatif.</li>
                        <li>+ Lettrage minimum pour la finition Broderie : 5 mm.</li>
                        <li class="mt-4">- Ne permet pas les dégradés de couleur.</li>
                        <li>- Certaines finesses ne peuvent pas être reproduites.</li>
                        <li>- Non disponible sur les vêtements haute visibilité.</li>
                    </ul>
                </div>
                <span v-if="isEmbroideryDisabled && unavailableLocationsForEmbroidery.length > 0" class="text-red-600">
                    Cette finition n'est pas disponible pour ces emplacements :
                    {{ unavailableLocationsForEmbroidery.join(", ") }}
                </span>
            </div>
        </div>
        <div class="mt-4 flex justify-between">
            <button
                type="button"
                class="flex border border-theme-blue bg-white px-4 py-2 uppercase text-theme-blue"
                @click="previousStep"
            >
                précédent
            </button>
            <button type="button" class="flex bg-theme-blue px-4 py-2 uppercase text-white" @click="nextStep">
                suivant
            </button>
        </div>
    </div>
</template>

<script>
import { getLocationName } from "../../helpers/locationHelper";
import { isLocationProductAvailableForFinishing } from "../../helpers/projectHelper";
import { PRODUCT_FINISHING_EMBROIDERY, PRODUCT_FINISHING_PRINTING } from "../../services/constants";
import eventBus from "../../services/eventBus";

export default {
    name: "finishing",
    data() {
        return {
            PRODUCT_FINISHING_PRINTING,
            PRODUCT_FINISHING_EMBROIDERY,
        };
    },
    computed: {
        article() {
            return this.$store.getters["project/currentArticle"];
        },
        articles() {
            return this.$store.getters["project/articles"];
        },
        product() {
            return this.$store.getters["project/productConfiguration"];
        },
        isPrintingDisabled() {
            const isFinishingPreventByLocations = this.isFinishingDisabled(PRODUCT_FINISHING_PRINTING);
            return !this.product.minQuantity.printing || isFinishingPreventByLocations;
        },
        isEmbroideryDisabled() {
            const isFinishingPreventByLocations = this.isFinishingDisabled(PRODUCT_FINISHING_EMBROIDERY);
            return !this.product.minQuantity.embroidery || isFinishingPreventByLocations;
        },
        unavailableLocationsForPrining() {
            return this.getUnavailableLocationsForFinishing(PRODUCT_FINISHING_PRINTING).map((location) =>
                getLocationName(location),
            );
        },
        unavailableLocationsForEmbroidery() {
            return this.getUnavailableLocationsForFinishing(PRODUCT_FINISHING_EMBROIDERY).map((location) =>
                getLocationName(location),
            );
        },
    },
    methods: {
        selectFinishing(finishing) {
            if (this.product.minQuantity[finishing.toLowerCase()] && !this.isFinishingDisabled(finishing)) {
                this.$store.dispatch("project/updateCurrentArticle", { prop: "finishing", value: finishing });
            }
        },
        getUnavailableLocationsForFinishing(finishing) {
            const unavailableLocations = [];
            this.article.customization.forEach((customization) => {
                const isLocationAvailable = isLocationProductAvailableForFinishing(
                    this.product.reference,
                    customization.location,
                    finishing,
                );
                if (!isLocationAvailable) {
                    unavailableLocations.push(customization.location);
                }
            });
            return unavailableLocations;
        },
        isFinishingDisabled(finishing) {
            const isFinishingPreventByLocations = this.article.customization.some(
                (customization) =>
                    !isLocationProductAvailableForFinishing(this.product.reference, customization.location, finishing),
            );
            return isFinishingPreventByLocations;
        },
        nextStep() {
            eventBus.emit("setTab", 4);
        },
        previousStep() {
            eventBus.emit("setTab", 2);
        },
    },
};
</script>
