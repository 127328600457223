function getTailwindColorClass(color) {
    switch (color) {
        case "BLACK":
            return "font-black";
        case "WHITE":
            return "font-white";
        case "GRAY":
            return "font-gray";
        case "BLUE":
            return "font-blue";
        case "LIGHT_BLUE":
            return "font-light-blue";
        case "NAVY":
            return "font-navy";
        case "RED":
            return "font-red";
        case "GREEN":
            return "font-green";
        case "ORANGE":
            return "font-orange";
        case "YELLOW":
            return "font-yellow";
        case "PURPLE":
            return "font-purple";
        case "BROWN":
            return "font-brown";
        default:
            return "font-black";
    }
}

function getColorLabel(color) {
    switch (color) {
        case "BLACK":
            return "Noir";
        case "WHITE":
            return "Blanc";
        case "GRAY":
            return "Gris";
        case "YELLOW":
            return "Jaune fluo";
        case "ORANGE":
            return "Orange fluo";
        case "BLUE":
            return "Bleu marine";
        case "BROWN":
            return "Marron";
        case "DEEPGRAY":
            return "Gris foncé";
        case "ANTHRACITE":
            return "Anthracite";
        case "RED":
            return "Rouge";
        default:
            return color;
    }
}

function getTailwindColorModel(color) {
    switch (color) {
        case "BLACK":
            return "bg-product-black";
        case "WHITE":
            return "bg-product-white";
        case "GRAY":
            return "bg-product-gray";
        case "YELLOW":
            return "bg-product-yellow";
        case "ORANGE":
            return "bg-product-orange";
        case "BLUE":
            return "bg-product-blue";
        case "BROWN":
            return "bg-product-brown";
        case "DEEPGRAY":
            return "bg-product-deepgray";
        case "ANTHRACITE":
            return "bg-product-anthracite";
        case "RED":
            return "bg-product-red";
        default:
            return color;
    }
}

export { getTailwindColorClass, getColorLabel, getTailwindColorModel };
